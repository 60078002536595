.signup-alert {
  width: 100%;
  border-width: 1px;
  border-color: rgb(203, 214, 226);
  border-style: solid;
  border-radius: 7px;
  background-color: rgb(247, 250, 252);
  font-weight: 500;
  text-align: center;
  padding: 18.5px 0;
  height: 60px;
  margin-top: 20px;
  color: #333333;
}

.signup-alert__link {
  font-weight: 600;
  cursor: pointer;
  color: #0052cc;
  margin-left: 10px;
  text-transform: capitalize;
}

.signup-alert__link:hover {
  color: #3572b0;
}
