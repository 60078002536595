.button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  background-color: #ffffff;
  font-size: 13px;
  font-family: "Montserrat";
  color: rgb(31, 50, 95);
  line-height: 1.2;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
}

.button svg {
  margin-right: 6px;
}

/* Primary */

.button--primary {
  border: 1px solid rgb(223, 225, 230);
  border-radius: 4px;
  box-shadow: 0px 3px 4px 0px rgba(111, 116, 126, 0.12);
  color: #1f325f;
  width: fit-content;
}
.button--primary span {
  padding: 8px;
}
.button--primary svg {
  margin: 0px 0px 0px 10px;
}
.button--primary svg path {
  fill: #1f325f;
}
.button--primary:hover {
  box-shadow: 0px 12px 26.6px 1.4px rgba(111, 116, 126, 0.22);
  color: #3572b0;
}
.button--primary:hover svg path {
  fill: #3572b0;
}

/* Splitted */

.button--splited svg {
  margin-top: 2px;
  margin-right: 10px;
}
.button--splited span {
  padding-left: 10px;
  border-left: 1px solid rgb(223, 225, 230);
  font-weight: 600;
}
.button--splited:hover span {
  background-color: #f1f5f7;
}

/* Save */

.button--save {
  color: white;
  background: #0052cc;
  font-weight: 600;
}
.button--save span {
  padding: 10px;
}
.button--save:hover {
  color: white;
  background: #3572b0;
}
.button--save sup {
  display: none;
  font-weight: 500;
}

@media (min-width: 992px) {
  .button--save sup {
    display: initial;
  }
}

/* Cancel */

.button--cancel {
  font-weight: 600;
  margin-right: 15px;
}
.button--cancel span {
  padding: 10px;
}
.button--cancel:hover {
  background: #f1f5f7;
}
.button--cancel sup {
  display: none;
  color: #f00;
  font-weight: 500;
}

@media (min-width: 992px) {
  .button--cancel sup {
    display: initial;
  }
}

/* Submit */

.button--submit {
  font-size: 14px;
  border-radius: 4px;
  background-color: rgb(0, 82, 204);
  box-shadow: 0px 3px 4px 0px rgba(111, 116, 126, 0.12);
  padding: 12px 40px;
  text-transform: uppercase;
  width: fit-content;
  font-weight: 500;
  color: #ffffff;
  margin: 20px 0;
}

.button--submit:hover {
  background-color: rgb(53, 114, 176);
  box-shadow: 0px 12px 26.6px 1.4px rgba(111, 116, 126, 0.22);
}
