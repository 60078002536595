@import url("./typeface.css");

body {
  font-family: "Montserrat", Tahoma, Geneva, Verdana, sans-serif;
}

.skeleton {
  background: linear-gradient(
    -90deg,
    #efefef 0%,
    #fcfcfc 50%,
    #efefef 100%
  ) !important;
  background-size: 400% 400% !important;
  animation: pulse 1.6666s ease-in-out infinite;
}

@keyframes pulse {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: -135% 0%;
  }
}

/**
 *
 * $small: "(max-width: 575.98px)";
 * $medium: "(min-width: 576px) and (max-width: 991.98px)";
 * $large: "(min-width: 992px)";
 *
 *
 *  Example of use
 *    
 *  @media (max-width: 575.98px) {
 *    p {
 *      font-size: 18px;
 *    }
 *  }
 *
 *  @media (min-width: 576px) and (max-width: 991.98px) {
 *    p {
 *      font-size: 18px;
 *    }
 *  }
 *
*/
