.logo-upload {
    display: flex;
}

.logo-upload--large {
    --height: 64px;
    --width: 64px;
}

.logo-upload--small {
    --height: 48px;
    --width: 48px;
}

.logo-upload .image {
    height: var(--height);
    width: var(--width);
    margin-right: 8px;
    border-radius: 50%;
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.4s;
    position: relative;
}

.logo-upload .image__actions {
    opacity: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    transition: linear 0.2s; 
}

.logo-upload .image__actions:hover {
    opacity: 1;
}

.logo-upload .image__actions .actions__icon {
    position: absolute;
    right: -20px;
    top: -8px;
    cursor: pointer;
}

.logo-upload img {
    height: var(--height);
    width: var(--width);
    border-radius: 100%;
}

.logo-upload .title {
    color: rgb(31, 50, 95);
    font-weight: bold;
    line-height: 1.2;
}
.logo-upload--large .title {
    font-size: 17px;
}

.logo-upload--small .title {
    font-size: 15px;
}

.logo-upload .description {
    color: rgb(66, 82, 110);
}

.logo-upload--large .description {
    font-size: 14px;
    line-height: 1.5;
    max-width: 320px;
    margin: 20px 0;
    font-weight: 500;
}

.logo-upload--small .description {
    font-size: 13px;
    line-height: 1.2;
    margin: 5px 0 10px;
}

.logo-upload--large button {
    font-size: 14px;
    line-height: 1.2;
    font-weight: 600;
    padding-top: 24px;
    padding-bottom: 24px;
}

@media (min-width: 576px) {
    .logo-upload--large {
        --height: 92px;
        --width: 92px;
    }

    .logo-upload .image {
        margin-right: 16px;
    }
}
