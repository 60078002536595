.dropdown-account__name {
  margin-right: 1rem;
  font-weight: 600;
  color: var(--primary-color);
}

.dropdown-account__avatar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
}

.dropdown-account__item {
  display: flex;
  align-items: center;
  font-weight: 600;
  color: #42526e;
  padding: 6px 0;
}

.dropdown-account__item >*:first-child {
  width: 32px;
}

.ant-dropdown-menu-item-active .dropdown-account__item {
  color: var(--primary-color);
}