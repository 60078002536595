.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: fit-content !important;
}

.container--dashed {
  border: 1px dashed rgb(203, 214, 226);
  border-radius: 12px;
  background-color: rgb(247, 250, 252);
}

.container__content {
  padding: 28px 50px;
}

.container__footer {
  padding: 15px 20px;
  background-color: white;
  display: flex;
  justify-content: flex-end;
  border-top: 1px dashed rgb(203, 214, 226);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  margin: 0 calc(-1 * var(--bs-gutter-x, 0.75rem));
}

@media (max-width: 575.98px) {
  .container__content {
    padding: 15px 20px;
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .container__content {
    padding: 20px 30px;
  }
}