.sort-dropdown__title,
.sort-dropdown .ant-dropdown-menu-item {
  display: block;
  background-color: #f7fafc;
  color: #42526e;
  padding: 5px 50px 5px 10px;
}

.sort-dropdown__title,
.sort-dropdown .ant-dropdown-menu-item:not(:last-child) {
  border-bottom: solid 1px #dfe1e6;
}

.sort-dropdown__title {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.sort-dropdown .ant-dropdown-menu {
  padding: 0px;
  border: 1px solid #dfe1e6;
}

.sort-dropdown .ant-dropdown-menu-item:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.sort-dropdown .ant-dropdown-menu-item-selected {
  background-color: #fff;
  color: #0052cc;
  font-weight: bold;
}

.sort-dropdown .ant-dropdown-menu-item-selected::after {
  display: inline-block;
  position: absolute;
  right: 10px;
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12px' height='10px'%3E%3Cpath fill-rule='evenodd' fill='rgb(0, 82, 204)' d='M4.630,7.295 L1.205,3.775 L-0.000,5.142 L4.728,10.000 L12.000,1.260 L10.700,-0.000 L4.630,7.295 Z'/%3E%3C/svg%3E");
}
