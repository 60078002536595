.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 35px;
}

.header__title {
  font-size: 16px;
  font-family: "Montserrat";
  color: rgb(51, 51, 51);
  font-weight: bold;
}

.header__subtitle {
  font-size: 12px;
  font-family: "Arial";
  color: rgb(31, 50, 95);
  font-weight: bold;
  width: 100%;
  border-bottom: 1px solid rgb(223, 225, 230);
  padding-bottom: 10px;
}
