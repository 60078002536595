.organization-card {
  height: 83px;
  display: flex;
  justify-content: space-between;
  padding: 15px;
  margin: 6px 0px;
  align-items: center;
  border: 1px dashed rgb(203, 214, 226);
  background-color: white;
  border-radius: 5px;
  margin-right: 3px;
  cursor: pointer;
}

.organization-card .organization__image {
    height: 48px;
}

.organization-card,
.organization-card svg:last-child {
  transition: cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.15s;
}

.organization-card:hover {
  background-color: rgb(241, 245, 247);
}

.organization-card svg:last-child {
  transform: translate(-2px, 0);
}

.organization-card:hover svg:last-child {
  transform: translate(1px, 0);
}

.organization-card:hover svg:last-child path {
  fill: rgb(0, 82, 204);
}

.organization-card:first-child {
  margin-top: 0;
}

.organization-card:last-child {
  margin-bottom: 0;
}

.organization-card .details {
  flex-basis: 70%;
}

.organization-card .details__name {
  font-size: 16px;
  color: rgb(51, 51, 51);
  font-weight: bold;
  line-height: 1.2;
}

.organization-card .details__role {
  font-size: 13px;
  padding-top: 10px;
  color: rgb(66, 82, 110);
  line-height: 1.2;
}
